window.extension = (url) ->
  allowed = ['doc', 'pdf', 'xls', 'docx', 'xlsx', 'csv', 'xlsb'];
  extension = url.split('.').pop().toLowerCase();
  return (_.indexOf(allowed, extension) > -1);

$ ->
  $(document.body).on 'click', 'a', (e) ->
    link = e.currentTarget.href
    if $(@).attr('href').charAt(0) is "#" then return false

    if $(@).attr('target') == '_blank'
      window.open link
      return false

    if $(@).parents('.lang').length
      return

    if(domain(link) == domain(window.location.href) || domain(link) == 'carla.dev')
      if extension(e.currentTarget.href)
        window.open link
        return false
      if e
        e.preventDefault()

      App.navigate link.split(domain(link))[1]
    else
      if $(@).attr('href') && $(@).attr('href').indexOf('http') isnt -1
        if e
          e.preventDefault()
        window.open link
