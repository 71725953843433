class Slider
  slides: []
  i: -1
  n: 0
  currentSlide: null
  prevSlide: null
  nextSlide: null
  t: null
  speed: .5

  constructor: (@el) ->
    @init()

  init: =>
    self = @
    length = @el.find('.slide').length
    @el.find('.slide').each (index) ->
      self.slides.push @
      TweenLite.set @,
        xPercent: 40
        z: 0
      TweenLite.set $(@).find('h1'),
        xPercent: -40
        z: 0
    TweenLite.set @el.find('.slide--home'),
      xPercent: 0
      z: 0
    TweenLite.set @el.find('.slide--home h1'),
      xPercent: 0
      z: 0
    @next()

  next: =>
    @i = @i + 1
    if @i >= @slides.length then @i = 0
    @animate()

  prev: =>
    @i = @i - 1
    if @i < 0 then @i = @slides.length - 1
    @animate()

  go: (index) =>
    requestAnimFrame =>
      @i = index
      @animate()

  animate: =>
    self = @
    document.body.setAttribute 'class', @slides[@i].getAttribute('class').split('--')[1]

    _.forEach @slides, (slide, index) ->
      s = index - self.i
      c = s - (s * 2)

      $(slide).css('z-index', c + self.slides.length)

      if s >= 0
        to = 40 * s
      else
        to = -100

      requestAnimFrame ->
        TweenLite.to slide, self.speed + (.05 * s),
          xPercent: to
          z: 0
        TweenLite.to $(slide).find('h1'), self.speed + (.05 * s),
          xPercent: -to
          z: 0

  counter: =>
    # TODO: update counter
    # $('.counter .total').text @slides.length
    # $('.counter .current').text @i + 1
