#= require ./lib/**/*.coffee
#= require ./components/**/*.coffee

((window) ->
  behaviour = []
  ready = []
  $body =

  window.App =
    ANIMATION_DURATION: 400
    CACHE: new Cache
    SLIDER: null
    register: (callback) -> behaviour.push callback
    ready: (callback) -> ready.push callback
    behave: ($context) -> App.run behaviour, $context
    run: (collection, $context) ->
      i = 0
      while i < collection.length
        collection[i] $context
        i++
    boot: ->
      $body = $ document.body
      App.run ready, $body
      App.behave $body

      App.SLIDER = new Slider $('.slider')

      page '/', App.page
      page '/:id', App.page
      page.start
        click: false

    # helpers
    navigate: (url) ->
      page url
      #TODO: ga

    title: (body) ->
      if !body
        document.title = _.last document.title.split(' — ')
      else
        title = $(body).filter('title').text()
        document.title = title

    # page rendering
    page: (ctx) ->
      console.log ctx.params.id
      if ctx.params.id == 'concepten'
        App.SLIDER.go 1
      else if ctx.params.id
        if $('#' + ctx.params.id).length
          i = $('#' + ctx.params.id).index()
          App.SLIDER.go i
      else
        App.SLIDER.go 0

  $ App.boot

) window

#= require ./behaviour/**/*.coffee
