App.register ($context) ->
  $context.find('.nav-arrow-right').on 'click', (e) ->
    e.preventDefault()
    App.SLIDER.next()
  $context.find('.nav-arrow-left').on 'click', (e) ->
    e.preventDefault()
    App.SLIDER.prev()


# App.register(function($context) {
#   $slider = $context.find('.slider');
#
#   $slider.on('init', function(e, slick) {
#     $context.find('.nav-slides .counter .total').text(slick.slideCount);
#     $context.find('.nav-slides .counter .current').text(slick.currentSlide + 1);
#   });
#
#   $slider.on('beforeChange', function(e, slick, direction) {
#     // TODO: play sound
#
#     $slider.find('[data-background]').each(function() {
#         var _this = this;
#         _.defer(function() {
#           var $el = $(_this).addClass('animate-opacity'),
#               img = new Image();
#
#           img.src = $el.data('background');
#           img.onload = function() {
#             $el.css({
#               'background-image': 'url(' + img.src + ')'
#             });
#             $el.addClass('animate-opacity-done').removeAttr('data-background');
#           }
#         });
#     });
#   });
#
#   $slider.on('afterChange', function(e, slick, currentSlide) {
#     $context.find('.nav-slides .counter .current').text(currentSlide + 1);
#
#     var $nav = $('.nav-pages');
#     $nav.find('.active').removeClass('active');
#     console.log(currentSlide);
#     var currentId = $slider.find('.slick-slide').eq(currentSlide+1).data('id');
#
#     console.log(currentId);
#
#     if (!$nav.find('.nav-pages--'+currentId).length) {
#       currentId = $nav.find('li').first().find('a').data('page');
#     }
#
#     console.log('.nav-pages--'+currentId);
#
#     $nav.find('.nav-pages--'+currentId).addClass('active');
#   })
#
#   $slider.slick({
#     arrows: false,
#     speed: 400,
#     cssEase: 'easeInOut'
#   })
#
#   $context.find('.nav-arrow-right').click(function(e) {
#     $slider.slick('slickNext');
#   });
#
#   $context.find('.nav-arrow-left').click(function(e) {
#     $slider.slick('slickPrev');
#   });
# })
