class Cache
  constructor: ->
    @items = []

  get: (name) =>
    if @items.hasOwnProperty name
      return @items[name]

  has: (name) =>
    @items.hasOwnProperty name

  set: (name, content) =>
    @items[name] = content
