App.register ($context) ->
  $context.find('[data-background]').each ->
    requestAnimFrame =>
      $el = $(@).addClass 'animate-opacity'
      img = new Image
      img.src = $el.data('background')

      img.onload = ->
        $el.css 'background-image': 'url(' + img.src + ')'
        $el.addClass('animate-opacity-done').removeAttr 'data-background'

  $context.find('img[data-src]').each ->
    $el = $(@).addClass 'animate-opacity'
    img = new Image
    img.src = $el.attr 'data-src'
    $el.attr 'src', ''

    img.onload = ->
      $el.attr('src', img.src).removeAttr 'data-src'
      $el.addClass 'animate-opacity-done'
      $el.trigger 'load'
